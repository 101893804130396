<script>
  import QuestionAccordion from '@/components/QuestionAccordion.svelte'
  import Question from '@/components/Question.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  export let darkTheme = true
</script>

<Section center={true} background={darkTheme ? 'dark' : 'white'}>
  <slot name="header">
    <div class="mb-8">
      <h1 class:text-white={darkTheme}>Frequently Asked Questions</h1>
    </div>
  </slot>

  <QuestionAccordion>
    <Question title="¿FIXD funciona en mi carro?" expanded={true} {darkTheme}>
      <p>
        FIXD funciona con todos los coches y camiones de gasolina fabricados
        después de 1996 y con los de diésel fabricados después del 2008. El
        sensor FIXD y la aplicación pueden leer todos los códigos OBD2
        estandarizados de la mayoría de los vehículos de gasolina fabricados a
        partir de 1996. Los códigos mejorados como ABS, bolsas de aire,
        transmisión y TPMS NO son compatibles.
      </p>
    </Question>
    <Question title="¿Es fácil de usar?" {darkTheme}>
      <p>
        ¡SÍ! Lo mejor de FIXD es que está hecho literalmente para usarse con
        facilidad y simplicidad. Cualquiera puede instalarlo en su coche por su
        cuenta, sin herramientas o necesidad de acudir a un mecánico. Y una vez
        que lo conectas a la aplicación gratuita de FIXD en tu teléfono
        inteligente, te dice con un lenguaje claro y sencillo qué le pasa a tu
        coche y cuál es la gravedad del problema para que puedas tomar una
        decisión informada. Incluso puedes conectar varios sensores a un
        teléfono, lo que te permitirá monitorear los vehículos de todos los
        miembros de tu familia desde un solo lugar.
      </p>
      <p class="mt-4">
        Si por alguna razón tienes alguna pregunta o problemas para instalarlo,
        simplemente

        <a
          href="https://www.fixd.com/help"
          class="underline text-green font-bold">manda un mensaje aquí.</a
        >.
      </p>
    </Question>
    <Question
      title="¿Qué tipo de información puede darme FIXD sobre mi carro?"
      {darkTheme}
    >
      <p>
        El sensor FIXD y la aplicación gratuita pueden indicarte en términos
        sencillos lo que te indica la luz de control del motor (check engine),
        la gravedad del problema y las consecuencias de seguir conduciendo, así
        como cuándo es el momento de realizar el mantenimiento rutinario. FIXD
        lee más de 7,000 códigos OBD2 estandarizados de control del motor; sin
        embargo, no puede leer códigos mejorados (como TPMS, ABS, bolsas de aire
        y transmisión) en este momento.
      </p>
    </Question>
    <Question
      title="¿FIXD es compatible con teléfonos inteligentes Android o iPhone?"
      {darkTheme}
    >
      <p>
        ¡SÍ! La aplicación gratuita FIXD es compatible tanto para iPhones como
        para teléfonos inteligentes Android. Solo se tarda unos minutos en
        configurar y empezar a monitorear la salud de tu auto durante todo el
        día, previniendo el gasto de miles de dólares en costos de reparación y
        brindándote más confianza y control en el taller mecánico.
      </p>
    </Question>
    <Question title="¿Tengo que pagar una suscripción?" {darkTheme}>
      <p>
        No. El sensor FIXD y la aplicación gratuita son 100% gratis y las puedes
        usar tantas veces como quieras, en tantos vehículos como sea necesario.
        SI te gustaría conocer nuestras funciones, como la estimación de costos
        y la línea directa con el mecánico, puedes probar FIXD Premium.
      </p>
    </Question>
    <Question title="¿Qué es FIXD Premium?" {darkTheme}>
      <p>
        FIXD Premium es la versión extendida de nuestra aplicación gratuita de
        FIXD que ofrece estimaciones de costos precisas para tus reparaciones
        específicas, te da acceso a los mecánicos FIXD de guardia y asistencia
        de bricolaje, y te ayuda a manejar cualquier problema del carro con
        confianza. Esta actualización es 100% opcional y no es necesaria para
        utilizar el sensor FIXD para diagnosticar la luz de control del motor
        (check engine). Conoce más sobre <a
          href="https://www.fixd.com/premium/home"
          class="underline text-white font-bold">FIXD Premium aquí</a
        >.
      </p>
    </Question>
  </QuestionAccordion>

  <slot name="cta" />
</Section>
