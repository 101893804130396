<script>
  import Benefits from '@/pages/scorpio/spanish/BenefitsSpanish.svelte'
  import OtherOBD2 from '@/pages/scorpio/spanish/OtherOBD2Spanish.svelte'
  import Compatibility from '@/pages/scorpio/spanish/CompatibilitySpanish.svelte'
  import TryRiskFree from '@/pages/scorpio/spanish/TryRiskFreeSpanish.svelte'
  import Mission from '@/pages/scorpio/spanish/MissionSpanish.svelte'
  import FAQ from '@/pages/scorpio/spanish/FAQSpanish.svelte'
  import About from '@/pages/scorpio/spanish/AboutSpanish.svelte'
  import BuyBoxL007 from '@/pages/scorpio/spanish/BuyBoxL007Spanish.svelte'

  import AsSeenOn from '@/components/landing-page/AsSeenOn.svelte'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'

  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Banner from '@/components/landing-page/Banner.svelte'

  import {
    Lightning,
    SalesTag,
    ShippingTruck,
    Certificate,
  } from '@/components/icons'

  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  import Money from '@/util/money.mjs'

  export let funnel
  export let gateway

  // TODO: load this from funnel properties rather than hard-coding
  const price = new Money({ amount: 19.99, currency: 'USD' })
</script>

<main class="md:pb-0">
  <div class="hidden md:block">
    <div class="banner bg-green py-2.5">
      <div class="flex justify-evenly w-100 text-white font-bold font-inter">
        <div class="flex items-center">
          <div class="mr-3">
            <SalesTag color="white" />
          </div>
          <p class="text-xl">!Obtén un 67% de Descuento¡</p>
        </div>
        <div class="flex basis-1/3 border-x justify-center item-center">
          <div class="mr-3">
            <ShippingTruck color="white" />
          </div>
          <p class="text-xl">¡Envío Gratis!</p>
        </div>
        <div class="flex">
          <div class="mr-3">
            <Certificate color="white" />
          </div>
          <p class="text-xl">¡Garantía Gratis de 1 Año!</p>
        </div>
      </div>
    </div>
    <Banner buyNowText="Comprar ahora" buyUrl="/buy/sensor67-es" />
  </div>

  <div class="block md:hidden">
    <div class="flex font-inter items-center leading-tight">
      <div class="bg-yellow py-1 px-2 h-8">
        <p class="uppercase font-bold text-10 text-center">
          trato <br /> relámpago
        </p>
      </div>
      <div class="bg-green flex gap-2 items-center pl-1.5 h-8 w-full">
        <Lightning color="white" size="sm" />
        <p class="text-white my-0 text-center font-semibold text-xs">
          67% OFF + Envío Gratis + Garantía Gratis de 1 año
        </p>
      </div>
    </div>
    <Banner
      showMenu={true}
      height="small"
      fillShoppingCardIcon={false}
      buyNowText="Comprar ahora"
      buyUrl="/buy/sensor67-es"
    />
  </div>

  <Section center="true" verticalPadding="none">
    <div class="pt-5">
      <div class="block md:hidden">
        <div class="font-inter mt-[-8px]">
          <h1 class="text-lg sm:text-4xl title bold" id="bannerHeader">
            Descifra las luces de control del motor (check engine) al instante
          </h1>
        </div>
      </div>
    </div>
  </Section>

  <div class="max-w-[1440px] mx-auto">
    <BuyBoxL007 {funnel} {gateway} />
  </div>

  <div id="asSeenOn">
    <AsSeenOn />
  </div>

  <Benefits />

  <OtherOBD2 {price}>
    <JumpToBuyBoxButton slot="cta" size="large">
      <span class="uppercase">OBTÉN FIXD AHORA CON UN 67% DE DESCUENTO</span>
    </JumpToBuyBoxButton>
  </OtherOBD2>

  <Compatibility>
    <JumpToBuyBoxButton slot="cta" maxWidth={56}>
      <span class="uppercase">BUY FIXD NOW</span>
    </JumpToBuyBoxButton>
  </Compatibility>

  <LiveReviews language="es">
    <div slot="header">
      <h3 class="text-xl font-normal mb-2" id="live_reviews">
        No te fíes solo de nuestra palabra...
      </h3>
      <h1 class="font-semibold md:font-bold text-xl mt-0">
        ¿Qué están diciendo otros conductores sobre FIXD?
      </h1>
    </div>
  </LiveReviews>

  <TryRiskFree>
    <JumpToBuyBoxButton slot="cta" size="medium">
      <span class="uppercase">OBTÉN FIXD AHORA CON UN 67% DE DESCUENTO</span>
    </JumpToBuyBoxButton>
  </TryRiskFree>

  <Mission />

  <FAQ />

  <About />

  <Footer />
</main>
