<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Banner from '@/components/landing-page/Banner.svelte'
  import HeroImage from '@/images/landing-page/scorpio/hot-new-car-gadget-hero.jpg'
  import { onMount } from 'svelte'

  import BusinessInsiderImage from '@/images/landing-page/scorpio/business-insider.png'
  import ForbesImage from '@/images/landing-page/forbes-simple.png'
  import CarAndDriverImage from '@/images/landing-page/scorpio/car-and-driver-color.png'
  import MashableImage from '@/images/landing-page/scorpio/mashable-round.png'
  import FoxImage from '@/images/landing-page/scorpio/fox-color.png'

  import PlugInGif from '@/images/landing-page/scorpio/plug-fixd-in.gif'
  import PlugInVideo from '@/images/landing-page/scorpio/plug-in-play.mp4'

  import {
    Gear,
    ThumbsUp,
    Road,
    MoneyBill,
    Chart,
    CarBurst,
  } from '@/components/icons'

  $: checkoutLink = 'https://shop.fixdapp.com/lp/sensor67-ca'

  onMount(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        if (this.getAttribute('href').includes('#')) {
          e.preventDefault()

          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
          })
        }
      })
    })
  })

  const date = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
</script>

<main class="font-montserrat">
  <div class="shadow-lg hidden md:block">
    <Banner darkTheme={false} buyUrl={checkoutLink} />
  </div>
  <div class="shadow-lg block md:hidden">
    <Banner darkTheme={false} showMenu={false} centerLogo={true} />
  </div>
  <Section verticalPadding="short">
    <div class="max-w-screen-md lg:max-w-[940px] mx-auto">
      <div class="bg-[#fff2cc] px-[3%] py-[2%]">
        <p class="text-sm">
          <span class="font-bold">UPDATE</span>- The
          <a
            href={checkoutLink}
            class="text-blue-600 hover:cursor-pointer font-bold">67% OFF</a
          > sale is still going
        </p>
      </div>

      <header class="text-center mt-5 mb-7">
        <h1 class="text-2xl xs:text-4xl font-bold xs:font-semibold mb-5">
          The Hottest New Car Gadget of 2024
        </h1>

        <h2 class="text-lg xs:text-2xl font-normal xs:font-semibold">
          "It can save you thousands on car repairs."
        </h2>
      </header>

      <article>
        <div class="px-2 mb-5">
          <p class="text-sm">{date}</p>
        </div>

        <div class="mx-auto w-full xs:w-3/4">
          <img
            src={HeroImage}
            alt="100% money-back guarantee"
            class="rounded-3xl"
          />
        </div>
        <div class="py-8 my-5 xs:py-0 text-center">
          <h3 class="text-2xl font-medium">
            Over 422,403 ⭐⭐⭐⭐⭐ 5-Star Reviews and Counting
          </h3>
        </div>
      </article>

      <article>
        <div
          class="grid grid-row-2 grid-cols-3 xs:grid-cols-5 gap-4 items-center justify-center py-[2%]"
        >
          <div class="text-center">
            <img
              src={BusinessInsiderImage}
              alt="business insider"
              class="w-[90%]"
            />
          </div>
          <div class="text-center">
            <img
              src={CarAndDriverImage}
              alt="business insider"
              class="w-[90%]"
            />
          </div>
          <div class="text-center">
            <img src={ForbesImage} alt="business insider" class="w-4/5" />
          </div>
          <div class="text-center">
            <img src={MashableImage} alt="business insider" class="w-4/5" />
          </div>
          <div class="text-center">
            <img src={FoxImage} alt="business insider" class="w-1/2" />
          </div>
        </div>

        <div class="py-[10%] xs:py-[2%] md:pb-[5%] text-center">
          <p class="italic text-sm font-semibold lg:font-medium">
            "Imagine owning a device that could practically replace your
            mechanic."
          </p>
        </div>
      </article>

      <article class="space-y-4 text-sm leading-5">
        <p>
          I'm back from the car repair shop, and <span class="font-bold"
            >I'm so mad, I could spit nails!</span
          >
        </p>
        <p>
          It all started last week when I went out to start my Honda Accord. The
          <span class="font-bold">"check engine light"</span> popped up
          <span class="font-bold">on my dashboard</span>, and it felt like my
          heart sank to the ground.
        </p>
        <p>You probably know the feeling.</p>
        <p class="italic">"Oh no, this is gonna cost a fortune in repairs!""</p>
        <p>
          I didn't want to "play mechanic" myself, spending hours under a grimy
          hood trying to figure out what was wrong.
        </p>
        <p>So I thought my local repair shop could tell me the problem.</p>
      </article>

      <article class="space-y-4 text-sm leading-5 mt-5">
        <h2 class="font-bold mb-2">"They Tried to Charge Me $2,300…"</h2>
        <p>
          All I wanted was for a mechanic to <span class="italic"
            >diagnose my check engine light…</span
          >
        </p>
        <p>
          But can you believe <span class="font-bold"
            >they tried to charge me $2,300</span
          >
          to fix the car… including a
          <span class="font-bold">$300 diagnostic fee</span>?!
          <span class="font-bold">I drove away feeling absolutely livid</span>
          (and without paying him a penny)!
        </p>
        <p>
          But even though I dodged a bullet, that experience woke me up to a
          shocking fact:
        </p>
      </article>
      <article class="space-y-4 text-sm leading-5 mt-5">
        <h2 class="font-bold mb-2">Car Repair Costs Are Skyrocketing!</h2>
        <p>
          If you're like me, <span class="font-bold"
            >paying a car repair bill</span
          >
          probably feels like you're
          <span class="font-bold">getting ripped off</span>. And with costs on
          the rise, it's only going to get
          <span class="italic">more painful</span> - the typical repair bill now
          hovers around $550!
        </p>
        <p>
          If your stomach knots up at the sight of a "check engine" light, <span
            class="italic">that's why.</span
          >
          You never know if you're going to
          <span class="font-bold"
            >get by with an easy fix… or face a <span class="italic"
              >budget-crushing repair</span
            ></span
          >
          costing you <span class="italic">thousands!</span>
        </p>
        <p>
          Even worse, some mechanics will try to sell you on unneeded repairs,
          jacking up your estimate by <span class="italic"
            >hundreds or thousands of dollars.</span
          >
        </p>
        <p>
          It's almost enough to make you want to quit driving and sell your car.
        </p>
        <p>
          <span class="italic">I get it</span>. I got
          <span class="font-bold">sick of paying high car repair bills</span> too.
        </p>
        <p>
          <span class="italic">Fortunately, now</span>
          <span class="font-bold">I don't have to - and neither do you</span>.
        </p>
        <p>Listen to this…</p>
        <p>
          I was on a treadmill at the gym later that day, and the guy beside me
          started telling me about an <span class="font-bold"
            >inexpensive device</span
          >
          that could
          <span class="font-bold">pinpoint the problem in seconds</span>…
          without even having a mechanic around.
        </p>
        <p>
          "It <span class="font-bold"
            >nails down car problems with 99% accuracy</span
          >, and you can even
          <span class="font-bold">clear the check engine light yourself,"</span>
          he told me.
        </p>
        <p>
          "You can even talk to live mechanics to help you with questions,
          repairs, and shop estimates.""
        </p>
        <p>
          My eyes lit up. <span class="italic">
            "I've got to get one of these!"</span
          >
        </p>
      </article>
      <article class="space-y-4 text-sm leading-5 mt-5">
        <h2 class="font-bold mb-2">
          The Car Tech Gadget That Saved Me $2,200 With One Use.
        </h2>
        <p>
          I went to the website he told me about and read some of the <span
            class="font-bold">422,403+ reviews from real drivers</span
          > - hard-working people like you and me.
        </p>
        <p>
          Then I noticed that the device came with a 30-day, <span
            class="font-bold">100% money-back guarantee.</span
          > So I knew I wasn't going to risk anything by trying it out. I decided
          to pull the trigger and see if it could really help me fix my car.
        </p>
        <p>Within just a few days of ordering, my device arrived.</p>
        <p>
          I completed the simple setup process, scanned my car, and <span
            class="font-bold">found out what the problem was</span
          >
          - all in about <span class="font-bold">one minute.</span>
        </p>
        <p>
          Then, I used the app to talk with an expert mechanic, who offered to
          walk me through the repair at no extra cost.I was able to <span
            class="font-bold"
            >fix it myself in about an hour… for <span class="italic"
              >less than $100.
            </span></span
          >
        </p>
        <p class="italic">
          I can't believe one little device just saved me $2,200!
        </p>
        <p>
          Now, for the first time, you can <span class="font-bold"
            >save $1,000s on car repairs,</span
          >
          diagnose problems with 99% accuracy, and know exactly what those repairs
          should cost to fix -
          <span class="italic">all from your smartphone!</span>
        </p>
        <p>You don't even need to set foot in the repair shop.</p>
      </article>

      <article class="space-y-4 text-sm leading-5 mt-5">
        <h2 class="font-bold mb-2">What is it?</h2>
        <p>
          It's called <a href={checkoutLink} class="text-blue-600 font-bold"
            >FIXD</a
          >, and it's helping millions of everyday drivers just like you save
          $1,000s in car repair costs.
        </p>
        <div class="pt-[3%] pb-[2%]">
          <div class="text-center">
            <img
              src={PlugInGif}
              alt="plug-fixd-in"
              class="rounded-xl xs:w-[40%]"
            />
          </div>
          <p class="mt-4">
            <a href={checkoutLink} class="text-blue-600">FIXD</a> is a pocket-sized
            sensor that plugs right under your car's steering wheel. Then it connects
            to your smartphone to tell you what's wrong with your car, help you save
            on maintenance and repairs, and even predict future problems your car
            is likely to have.
          </p>
        </div>
        <h5 class="text-sm">‍Your Mechanic’s Dirty Little Secret</h5>
        <p>
          Here's something your mechanic hopes you never find out: When they
          diagnose car problems, all they do is plug an OBD-II sensor - just
          like FIXD - into your car and read the error codes. That's how they
          know what's wrong with your car and how to fix it.
        </p>
        <div>
          <p class="mb-3">
            <a href={checkoutLink} class="text-blue-600">FIXD</a> does the same thing,
            but better:
          </p>
          <ul class="pl-5">
            <li>
              • Diagnoses check engine lights with 99% accuracy - no need to
              guess what's wrong with your car.
            </li>
            <li>
              • Explains car problems in simple terms, so you don't have to be a
              mechanic to understand them.
            </li>
            <li>
              • Shows you how severe the problem is, so you know whether to keep
              driving or fix it right now.
            </li>
            <li>
              • <a href={checkoutLink} class="text-blue-600 font-bold"
                >Clears check engine lights</a
              > for minor problems to give you peace of mind.
            </li>
            <li>
              • Costs a fraction of what your mechanic would charge you for just
              one scan.
            </li>
          </ul>
        </div>
      </article>

      <article class="space-y-4 text-sm leading-5 mt-5 pb-[3%]">
        <h2 class="font-bold mb-2">What Else I Love About FIXD:</h2>
        <ul class="pl-5 leading-5">
          <li>
            • It saves you the <span class="font-bold italic"
              >shame of letting your mechanic rip you off</span
            >. Now you'll know what you should pay before you set foot in the
            repair shop.
          </li>
          <li>
            • It <span class="font-bold">spares you the guesswork</span> and frustration
            of "playing mechanic."
          </li>
          <li>
            • It <span class="font-bold"
              >protects you from overspending by $1,000s</span
            > at the repair shop.
          </li>
          <li>
            • It <span class="font-bold">protects your family's safety</span> by
            alerting you to problems before they become major issues - even if your
            check engine light isn't on.
          </li>
          <li>
            • You can <span class="font-bold"
              >easily fix minor issues yourself</span
            >, thanks to FIXD's live Mechanic Hotline - which can save you
            $1,000s in inflated repair shop costs.
          </li>
        </ul>
      </article>

      <article class="space-y-4 text-sm leading-5 mt-5 pb-[5%]">
        <p class="font-medium">
          It works just like your mechanic's bulky, expensive pro scanner, but
          gives you much more:
        </p>
        <div class="flex border-0 md:border-black flex-col md:flex-row">
          <div class="flex-1 border-0 md:border-r md:border-black">
            <header class="bg-[#85ba67] text-center py-2.5">
              <h4 class="text-black-0">FIXD</h4>
            </header>
            <div class="bg-[#d2e6c8] p-2.5 space-y-4">
              <p class="paragraph-647">
                ✅ Works on all gas cars made after 1995
              </p>
              <p>
                ✅ <span class="font-bold"
                  >Clear your check engine light (with 1 tap)</span
                >
              </p>
              <p>✅ Accurately diagnoses 7,000+ car problems</p>
              <p>
                ✅ Explains car problems <span class="font-bold"
                  >in simple terms</span
                >
              </p>
              <p class="font-bold">
                ✅ Gives you an accurate repair cost estimate using AI, so you
                never overpay
              </p>
              <p>
                ✅ Gives you access to <span class="font-bold"
                  >expert live mechanics</span
                > to help with repairs and questions right now.
              </p>
              <p class="font-bold">
                ✅ Maintenance reminders to help you avoid unneeded repairs
              </p>
              <p>
                ✅ <span class="font-bold">Predict future issues</span> before they
                happen
              </p>
            </div>
            <footer
              class="bg-[#85ba67] text-center pt-3.5 xs:pt-5 pb-2.5 h-15 h-[70px]"
            >
              <div class="text-center text-base xs:text-lg">
                <h5 class="text-base xs:text-lg text-black-0">
                  Cost: <span class="strikethrough text-red">$59.99</span>
                </h5>
                <a href={checkoutLink} class="text-blue-600 font-bold"
                  >SEE PRICE</a
                >
              </div>
            </footer>
          </div>

          <div class="flex-1 pt-8 xs:pt-0">
            <header class="bg-[#c5c5c5] text-center py-2.5">
              <h4 class="text-black-0">Mechanic's Pro Scanner</h4>
            </header>
            <div class="bg-[#e0e0e0] p-2.5 space-y-4 min-h-[352px]">
              <p class="paragraph-647">
                ✅ Works on all gas cars made after 1995
              </p>
              <p>✅ Clear your check engine light</p>
              <p>✅ Accurately diagnoses 7,000+ car problems</p>
              <p>❌ Have to interpret confusing error codes</p>
              <p class="font-bold">
                ❌ Mechanics will still charge you up to $300 to diagnose your
                check engine light
              </p>
              <p>
                ‍❌ You have to go to the car repair shop, where your mechanic
                could try to rip you off
              </p>
              <p>❌ No support for maintenance reminders</p>
              <p class="font-bold">❌ Only shows current issues</p>
            </div>
            <footer class="bg-[#c5c5c5] text-center pt-5 h-[60px] xs:h-[70px]">
              <div class="text-center">
                <h5 class="text-base xs:text-lg text-black-0">Cost: $2,000+</h5>
              </div>
            </footer>
          </div>
        </div>
      </article>

      <article class="columns-1 md:columns-2 text-sm leading-5">
        <div class="flex-1 mt-5">
          <h2 class="font-bold mb-2 text-28">How does it work?</h2>
          <div class="space-y-4">
            <div class="mb-6">
              <a href={checkoutLink} class="text-blue-600 font-bold"
                >FIXD is easy to use! There are just three simple steps to get
                started:</a
              >
            </div>

            <p>
              <span class="font-bold">Step 1:</span> Plug your FIXD scanner into
              your car.
            </p>
            <p>
              <span class="font-bold">Step 2:</span> Open the FIXD app on your Android
              or Apple smartphone.
            </p>
            <p>
              <span class="font-bold">Step 3:</span> Click the green “Tap to Scan”
              button.
            </p>
          </div>
        </div>
        <div class="flex-1 mt-3 xs:mt-0">
          <video
            src={PlugInVideo}
            alt="plug in play"
            autoplay
            loop
            muted
            type="video/mp4"
            height="auto"
            class="rounded-2xl w-full xs:w-[400px] h-[600px] object-cover object-center"
          />
        </div>
      </article>

      <article class="space-y-4 text-sm leading-5 py-5">
        <p>
          But FIXD isn’t just a code reader - it provides resources to help you
          to save money by doing repairs yourself instead of letting a mechanic
          rip you off.
        </p>
        <p>
          For example, you can always <span class="font-bold">call the</span>
          <a href={checkoutLink} class="font-bold text-blue-600">
            FIXD Mechanic Hotline</a
          > during business hours. An expert mechanic will walk you through repairs,
          review shop estimates, answer questions about your car, and much more.
        </p>
        <p>
          You also get plenty of other tools to help you save money and time,
          including:
        </p>
      </article>

      <article class="md:flex leading-5 flex-wrap pb-[5%] mt-12">
        <div class="shrink-0 basis-1/2 text-center mb-7 px-2.5">
          <Gear color="black" />
          <p class="text-lg font-bold my-2.5">
            Get Car Repair Help 24/7 from Experts
          </p>
          <p class="text-sm">
            <a href={checkoutLink} class="text-blue-600">FIXD's</a>
            revolutionary new <span class="font-bold">AI mechanic</span> gives you
            access to expert help, so you can confidently repair your vehicle, even
            outside of normal business hours.
          </p>
        </div>
        <div class="shrink-0 basis-1/2 text-center mb-7 px-2.5">
          <ThumbsUp color="black" />
          <p class="text-lg font-bold my-2.5">
            No More Check Engine Light Anxiety
          </p>
          <p class="text-sm">
            Instantly diagnose check engine lights, so you don't have to worry
            about what that warning light means.
          </p>
        </div>
        <div class="shrink-0 basis-1/2 text-center mb-7 px-2.5">
          <Road color="black" />
          <p class="text-lg font-bold my-2.5">Know if it's Safe to Drive</p>
          <p class="text-sm">
            You'll find out how severe your car problem is, so you know whether
            it's safe to drive… or if you need to take care of the issue
            immediately.
          </p>
        </div>
        <div class="shrink-0 basis-1/2 text-center mb-7 px-2.5">
          <MoneyBill color="black" />
          <p class="text-lg font-bold my-2.5">
            Know What You Should Pay for Car Repairs
          </p>
          <p class="text-sm">
            FIXD gives you an estimated cost for your repairs, so you'll know
            what you should pay before you take your car to the shop.
          </p>
        </div>
        <div class="shrink-0 basis-1/2 text-center mb-7 px-2.5">
          <Chart color="black" />
          <p class="text-lg font-bold my-2.5">Predict Future Issues</p>
          <p class="text-sm">
            See which problems are likely in your car's future, and how much
            they'll cost to repair.
          </p>
        </div>
        <div class="shrink-0 basis-1/2 text-center mb-2 px-2.5">
          <CarBurst color="black" />
          <p class="text-lg font-bold my-2.5">Spot a Lemon - Instantly</p>
          <p class="text-sm">
            Thinking of buying a used car? FIXD gives you a complete report on
            any used car, so you know whether it's a great find… or a lemon.
          </p>
        </div>
      </article>

      <article class="space-y-4 text-sm leading-5 pt-[5%] pb-[10%] xs:pb-0">
        <h2 class="font-bold mb-7">
          Why is FIXD the Top Car Tech Device of 2024?
        </h2>
        <p>
          <a href={checkoutLink} class="text-blue-600 font-bold">FIXD</a> is getting
          very popular, and for good reason.
        </p>
        <p>
          Costing less than $30, it's being called, <span class="font-bold"
            >"this year's hottest car tech gadget"</span
          > on many of the big name sites.
        </p>
        <p>
          Since being picked up by all the big news outlets, <span
            class="font-bold">FIXD has sold over 3 million+ sensors</span
          >
          across the U.S. and Canada, with
          <a href={checkoutLink} class="text-blue-600 font-bold"
            >422,403 5-star ratings</a
          >
          ⭐⭐⭐⭐⭐.
        </p>
        <p>Listen to this…</p>
        <p>
          By the end of 2023 <a
            href={checkoutLink}
            class="text-blue-600 font-bold">FIXD</a
          >
          is expected to become
          <span class="font-bold">America's #1 best-selling car scanner</span>.
          Even professional mechanics are ditching their professional scan tools
          to use it too!
        </p>
        <p>
          <span class="font-bold">FIXD is 100% free to use forever</span> once
          you have the device.
          <span class="font-bold"
            >And over 100k people have opted-in to the optional <a
              href={checkoutLink}
              class="text-blue-600">FIXD Premium</a
            > membership</span
          > for around $8/month, which gives you access to powerful features. That's
          a bargain considering pro scan tools cost thousands of dollars.
        </p>
        <p>
          If you sign up through <a
            href={checkoutLink}
            class="text-blue-600 font-bold">their discount page</a
          >, you'll get access to their
          <span class="font-bold">no-commitment, free-trial</span> so you can
          experience all the extra features at
          <span class="font-bold">no additional cost to try it</span>.
        </p>
        <p>
          <span class="font-bold"
            >I bought some for my parents and kids for the holidays.</span
          >
          So if there's any issues with their cars, I can see that right from my
          phone! They love it too and
          <span class="font-bold"
            >I also love the peace of mind I get knowing they're safe.</span
          >
        </p>
        <p>
          <a href={checkoutLink} class="text-blue-600 font-bold">FIXD</a> even
          comes with a <span class="font-bold">FREE 1-year warranty</span> and a
          <span class="font-bold">30-day money-back guarantee</span>, so there's
          absolutely <span class="font-bold">no risk to you.</span> You can always
          return it.
        </p>

        <div>
          <div class="py-8 my-5 xs:py-0 text-center">
            <h3 class="text-2xl font-medium">
              Over 422,403 ⭐⭐⭐⭐⭐ 5-Star Reviews and Counting
            </h3>
          </div>
          <article>
            <h4 class="font-semibold mt-5 mb-3 text-center">As seen in</h4>
            <div
              class="grid grid-row-2 grid-cols-3 xs:grid-cols-5 gap-4 items-center justify-center py-[2%]"
            >
              <div class="text-center">
                <img
                  src={BusinessInsiderImage}
                  alt="business insider"
                  class="w-[90%]"
                />
              </div>
              <div class="text-center">
                <img
                  src={CarAndDriverImage}
                  alt="business insider"
                  class="w-[90%]"
                />
              </div>
              <div class="text-center">
                <img src={ForbesImage} alt="business insider" class="w-4/5" />
              </div>
              <div class="text-center">
                <img src={MashableImage} alt="business insider" class="w-4/5" />
              </div>
              <div class="text-center">
                <img src={FoxImage} alt="business insider" class="w-1/2" />
              </div>
            </div>
          </article>
        </div>
      </article>

      <article class="mt-[5%] mb-[3%]">
        <div class="text-center border-2 border-black-1 text-black-0">
          <header class="bg-green py-2.5">
            <h2 class="xs:text-white">Limited Time Savings</h2>
          </header>
          <div
            class="bg-yellow-200 space-y-4 font-bold text-sm leading-5 px-2.5 pt-2.5 pb-5"
          >
            <p>
              Right now, you can purchase FIXD at up to 67% off on our <a
                href={checkoutLink}
                class="text-blue-600">official website.</a
              >
            </p>
            <p>
              All FIXD sensors - regularly $59.99 - see the <a
                href={checkoutLink}
                class="text-blue-600">sale price here</a
              >!
            </p>
            <p>
              This is a limited time deal, so be sure to grab some FIXD sensors
              as gifts or for your family's cars today.
            </p>
          </div>
        </div>

        <div class="mt-10 flex justify-center">
          <a
            href={checkoutLink}
            class="text-sm no-underline font-bold text-white bg-green rounded-full px-4 py-4"
            >Click here to get 67% OFF FIXD</a
          >
        </div>
      </article>

      <article class="leading-5 text-sm text-center pt-[3%] px-[5%] pb-[5%]">
        <h2 class="font-semibold text-28">Real Reviews from Real Customers</h2>
        <div class="space-y-6 mb-2.5">
          <p>⭐⭐⭐⭐⭐ 5 Stars</p>
          <p>
            The FIXD Bluetooth OBD-2 Scanner for Car has become my trusted
            companion when it comes to understanding and monitoring my Prius
            2012. This handy device provides me with all the essential details
            about my car, empowering me to stay informed and make informed
            decisions about its maintenance and performance.
          </p>
          <p>
            Using the FIXD OBD2 scanner is easy as a breeze. With a simple
            <a href={checkoutLink} class="text-blue-600">plug-and-play setup</a
            >, I quickly connected it to my car's OBD-2 port and paired it with
            my smartphone via Bluetooth. From there, a world of valuable
            information about my Prius unfolded before my eyes.
          </p>
          <p>
            This scanner goes beyond just reading and clearing engine codes. It
            provides a comprehensive report on my vehicle's health, including
            real-time data on engine performance, fuel efficiency, battery
            voltage, and more. Having access to this information allows me to
            proactively address any issues. Ensuring that my Prius stays in
            top-notch condition.
          </p>
          <div>
            <p class="font-bold">Verified Purchase - May 31, 2023</p>
            <p class="italic">30 people found this helpful.</p>
            <p class="text-[#9a9a9a]">—</p>
          </div>
        </div>
        <div class="space-y-6 mb-2.5">
          <p>⭐⭐⭐⭐⭐ 5 Stars</p>
          <p>
            This is perfect for the homeowner/ mechanic who wants to know more
            about the condition of the vehicle and the status of service lights.
            Works well with apple and great source of knowledge for codes.
            Bought one as a gift for my son.
          </p>
          <div>
            <p class="font-bold">Verified Purchase - December 10, 2023</p>
            <p class="italic">10 people found this helpful.</p>
            <p class="text-[#9a9a9a]">—</p>
          </div>
        </div>
        <div class="space-y-6 mb-2.5">
          <p>⭐⭐⭐⭐⭐ 5 Stars</p>
          <p>
            After unpacking and downloading the app, I plugged it into my
            vehicle and the process began. <a
              href={checkoutLink}
              class="text-blue-600">FIXD</a
            > was ready to get my car fixed!!! The instructions are very easy to
            follow.
          </p>
          <p>
            The FIXD product/tool is a vehicle owner's dream. The information
            that's offered, the assistance and help that is recommended is
            phenomenal!!!
          </p>
          <p>
            FIXD is a straightforward deep scanning auto tool that gave me
            results in a flash!
          </p>
          <div>
            <p class="font-bold">Verified Purchase - November 22, 2023</p>
            <p class="italic">15 people found this helpful.</p>
            <p class="text-[#9a9a9a]">—</p>
          </div>
        </div>
        <div class="space-y-6 mb-2.5">
          <p>⭐⭐⭐⭐⭐ 5 Stars</p>
          <p>
            I got this right after my service light came on. I was able to
            diagnose the problem and tell the mechanic what's wrong. I got the
            car fixed and feel better about driving the car. Not worried about
            the cost, because the app tells me everything.
          </p>

          <div>
            <p class="font-bold">Verified Purchase - November 9, 2023</p>
            <p class="italic">13 people found this helpful.</p>
            <p class="text-[#9a9a9a]">—</p>
          </div>
          <div class="w-full mx-auto xs:w-1/2">
            <a
              href={checkoutLink}
              class="text-sm w-full block no-underline font-bold text-white bg-green rounded-full px-4 py-2"
              >Click here to get 67% OFF FIXD</a
            >
          </div>
        </div>
      </article>
      <article class="space-y-5 text-sm leading-5 text-center pb-[10%] pt-[3%]">
        <h2 class="text-4xl">FAQ</h2>
        <div>
          <p class="font-bold">Does FIXD work on my car?</p>
          <p>
            <a href={checkoutLink} class="text-blue-600">[FIXD]</a> works with all
            gas-powered cars, trucks, and SUVS made after 1995, and all passenger
            diesel vehicles made after 2007. It can read over 7,000 check engine
            codes, as well as ABS and brakes, airbag, battery, wheels and tires,
            and many more.
          </p>
        </div>
        <div>
          <p class="font-bold">
            Can it really tell me what's wrong with my car?
          </p>
          <p>
            Yes.<a href={checkoutLink} class="text-blue-600">[FIXD]</a> gives you
            explanations of more than 7,000 check engine light codes, in simple terms
            everyone can understand.
          </p>
        </div>
        <div>
          <p class="font-bold">Is it available on Apple and Android phones?</p>
          <p>
            Yes. The free <a href={checkoutLink} class="text-blue-600">[FIXD]</a
            > app is compatible with both iPhones and Android smartphones.
          </p>
        </div>
        <div>
          <p class="font-bold">Is there any advantage to ordering now?</p>
          <p>
            Yes!
            <a href={checkoutLink} class="text-blue-600">[FIXD]</a> has extended
            its holiday sale to make it affordable to get FIXD for yourself and your
            loved ones. Right now, you can get FIXD for $19.99 instead of the usual
            $59.99. This sale won't last much longer, though.
          </p>
        </div>
      </article>
    </div>
  </Section>
  <div class="w-full bg-green h-[100px]" />
</main>
