// The import name must be the same as the file name to work.
// i.e. Sensor67 from '@/pages/Sensor67.svelte' works; NOT SensorTest from '@/pages/Sensor67.svelte

import WebpackerSvelte from 'webpacker-svelte'

import Holiday from '@/pages/scorpio/Holiday.svelte'
import HotNewCarGadget from '@/pages/scorpio/HotNewCarGadget.svelte'
import HotNewCarGadgetCA from '@/pages/scorpio/HotNewCarGadgetCA.svelte'
import Premium50 from '@/pages/scorpio/Premium50.svelte'
import Retargeting from '@/pages/scorpio/Retargeting.svelte'
import Sensor67BFCM from '@/pages/Sensor67BFCM.svelte'
import ThreeGrads from '@/pages/scorpio/ThreeGrads.svelte'
import ThreeTips from '@/pages/scorpio/ThreeTips.svelte'
import Sensor19 from '@/pages/Sensor19.svelte'
import Sensor22 from '@/pages/Sensor22.svelte'
import Sensor25 from '@/pages/Sensor25.svelte'
import Sensor28 from '@/pages/Sensor28.svelte'
import Sensor67 from '@/pages/Sensor67.svelte'
import Sensor67VSL from '@/pages/Sensor67VSL.svelte'
import Sensor67Spanish from '@/pages/Sensor67Spanish.svelte'
import Sensor67IrresistibleOffer from '@/pages/Sensor67IrresistibleOffer.svelte'
import Sensor67ConsumerAffairs from '@/pages/Sensor67ConsumerAffairs.svelte'
import Sensor67Quiz from '@/pages/Sensor67Quiz.svelte'

import BuyBoxL007 from '@/pages/scorpio/components/L007/BuyBoxL007.svelte'

import '@/components/jump_to_buy_box.js'
import '@/components/read_more.js'
import '@/components/landing_page_youtube_modal.js'
import '@/components/reviews.js'
import '@/components/landing-page-express-buttons.js'
import '@/elements/paypal-button.js'
import '@/elements/googlepay-button.js'
import '@/elements/applepay-button.js'
import '@/components/buy_box.js'
import '@/components/vehicle_compatibility.js'
import '@/components/scroll_to_reviews.js'
import '@/elements/splide-carousel.js'
import '@/components/i18n_picker.js'
import '@/components/variant_switcher.js'

WebpackerSvelte.setup({
  BuyBoxL007,
  Holiday,
  HotNewCarGadget,
  HotNewCarGadgetCA,
  Premium50,
  Retargeting,
  Sensor19,
  Sensor22,
  Sensor25,
  Sensor28,
  Sensor67,
  Sensor67BFCM,
  Sensor67Spanish,
  Sensor67IrresistibleOffer,
  Sensor67ConsumerAffairs,
  Sensor67Quiz,
  Sensor67VSL,
  ThreeGrads,
  ThreeTips,
})
